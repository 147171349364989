@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');

:root{
  --color-sand: #f2ab87;
  --color-green: #051f1f;
}

body {
  position: relative;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(204,204,204,1) 100%);
  background-color: #fff;
  overflow-x: hidden;
}
body::after{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(./assets/clouds.webp);
  background-size: 110% auto;
  background-position: 0% -50px;
  background-repeat: repeat-x;
  pointer-events: none;
  z-index: 1;
  animation: gradient-animation 120s linear infinite;
}

@keyframes gradient-animation {
  0% {background-position: 0% -50px;}
  100% {background-position: 3300% -50px;}
}

.inset-line {position: relative;}
.inset-line::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid var(--color-green);
  pointer-events: none;
}
.inset-line.line-orange::after {border-color: var(--color-sand);}

#menu-wrapper { 
  z-index: 1; 
  top: 40px; 
  transition: .3s ease-out;
}
#menu-wrapper.to-front {
  z-index: 9; 
  top: 20px 
}
#menu-wrapper.to-front .menu {
  @apply shadow-lg;
}

.menu {background-color: var(--color-sand);}
.menu nav ul li a {
  @apply hover:drop-shadow;
  display: block;
  padding: 0.75rem;
  color: var(--color-green);
  text-transform: uppercase;
  font-weight: 800;
  overflow: hidden;
  transition: .1s ease-out;
}
.menu nav ul li a:hover {
  color: #fff;
  text-shadow: 0px 0px 10px rgba(5,31,31,0.25);
}

.content-box {
  position: relative;
  color: #b4bcbc;
  background-image: url(./assets/content-bg.webp);
  z-index: 2;
}

.products .product {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-sand);
  padding: 20px 8px 0 8px;
  transform: translateY(0);
  transition: .2s ease-out;
}
.products .product:hover {
  @apply shadow-lg;
  transform: translateY(-15px);
}
.products .product h3 {font-size: .9rem; font-weight: 800;}
.products .product img {
  height: 130px;
  margin-left: auto;
  margin-right: auto;
}

input[type='text'], input[type='email'], input[type='tel'], textarea {
  @apply placeholder-[#051f1f];
  display: block;
  width: 100%;
  background-color: var(--color-sand);
  border-radius: 40px;
  margin-bottom: .5rem;
  padding: 1rem 2rem;
  font-weight: 500;
  outline: none;
}

button {
  @apply hover:brightness-125;
  background: var(--color-green) !important;
  color: var(--color-sand);
  padding: .5rem 2rem;
  border-radius: 40px;
  transition: .2s ease-out;
}

h1 {
  font-size: 3.5rem;
  line-height: 4.2rem;
  font-weight: 900;
}

h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: var(--color-sand);
  margin-bottom: 1rem;
}

h3 {
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 700;
  color: var(--color-green)
}

p{margin-bottom: 1rem;}

.color-green {color: var(--color-green);}
.bg-sand {background-color: var(--color-sand);}

@media (min-width: 1536px) {
  .container {
      max-width: 1280px;
  }
}